.App { text-align: center; }

.App-link { color: rgb(112, 76, 182); }

.headerSearchIcon
{
  /* padding-bottom: 11px; /* TODO: size based on the search field */
  padding-left:  .25em;
  align-self: center;  
}

.headerSearchIcon:hover { cursor: pointer; }

.twoColumn { margin: 10px; }
/*
.twoColumn:nth-child(odd)  { margin: 10px; }
.twoColumn:nth-child(even) { margin: 10px; }
*/
@media screen and (min-width: 601px)
{
  .twoColumn
  {
    display: grid;
    grid-template-columns: minmax(300px, 1fr) 1fr;
    /* grid-template-rows: 1 1fr, */
    grid-auto-flow: row dense;
    gap: 10px;
  }

  .twoColumn:nth-child(odd)  { justify-self: right; }
  .twoColumn:nth-child(even) { justify-self: left;  }
}

hr.sub-break { width: 80%; }

.amplify-storagemanager { margin: .25em; }

div.amplify-storagemanager__dropzone
{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 120px;
  overflow: scroll;
  margin: .25em;
  position: relative;
  box-sizing: border-box;
  transition: all .15s linear;
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 4px;
}

div.amplify-storagemanager__dropzone > .amplify-button
{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-weight: 500;
  margin: .25em;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

.dzu-input { display: none; }

/* Separate the list with a pipe | */
footer ul
{
  list-style-type: "|";
  list-style-position: outside;
}

/* Do not start the list with pipe */
footer ul li:first-child { list-style: none; }

/* display the list horizontally */
footer ul li
{
  display: inline list-item;
  list-style-type: "|";
  list-style-position: outside;
  margin-left:  0.5em;
  margin-right: 0.1em;
}

/* spacing after the | for the list style icon */
footer ul li *:first-child { margin-left:  0.5em; }

h3
{
  margin-top: 0;
  margin-bottom: 1em;
}

h4
{
  vertical-align: top;
  font-size: smaller;
  margin-top: 0;
  margin-bottom: 1em;
}